import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Container, Box, Divider, Button, Avatar } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

function ChangePhoto() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState('/path/to/current/photo.jpg'); 

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }
    };

    const handleUpload = () => {
        // Handle file upload logic here
        console.log("Upload file:", selectedFile);
    };

    const choosePhotoButtonStyle = {
        background: '#417690',
        color: '#fff',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        fontFamily: '"Roboto Condensed", sans-serif',
        letterSpacing: 1.2,
        fontWeight: 400,
        '&:hover': {
            background: '#5A8EA8',
        },
    };

    const uploadPhotoButtonStyle = {
        background: '#417690',
        color: '#fff',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        fontFamily: '"Roboto Condensed", sans-serif',
        letterSpacing: 1.2,
        fontWeight: 400,
        '&:hover': {
            background: '#5A8EA8',
        },
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 0 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%', padding: 2 }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontSize: '1.25rem',
                            textTransform: 'uppercase',
                            fontFamily: '"Roboto Condensed", sans-serif',
                            letterSpacing: 1.2,
                            fontWeight: 400,
                        }}
                    >
                        Change Photo
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    
                    <Card sx={{ boxShadow: 3, borderRadius: 2, backgroundColor: '#EDF4F7' }}>
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Avatar
                                    sx={{ 
                                        width: 120, 
                                        height: 120, 
                                        marginBottom: 2, 
                                        bgcolor: 'transparent', 
                                        border: '2px dashed #417690' 
                                    }}
                                >
                                    <CameraAltIcon sx={{ fontSize: 60, color: '#417690' }} /> 
                                </Avatar>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="upload-button"
                                    type="file"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="upload-button">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        sx={choosePhotoButtonStyle}
                                    >
                                        Choose Photo
                                    </Button>
                                </label>
                                <Button
                                    variant="contained"
                                    sx={{ ...uploadPhotoButtonStyle, marginTop: 2 }}
                                    onClick={handleUpload}
                                    disabled={!selectedFile}
                                >
                                    Upload Photo
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Container>
    );
}

export default ChangePhoto;
