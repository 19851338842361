import { React, useState } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Container, Typography, Button, Box, TableCell } from '@mui/material';
import { Add } from '@mui/icons-material'; 

const columns = [
    {
        name: "wishlistId",
        label: "Wishlist ID",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "customerName",
        label: "Customer Name",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "createdDate",
        label: "Created Date",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "itemCount",
        label: "Item Count",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value) => {
                return <span>{value}</span>;
            }
        }
    },
];

const data = [
    { wishlistId: "WISH001", customerName: "Alice Smith", createdDate: "2023-09-01", itemCount: 5 },
    { wishlistId: "WISH002", customerName: "Bob Johnson", createdDate: "2023-09-02", itemCount: 3 },
    { wishlistId: "WISH003", customerName: "Charlie Lee", createdDate: "2023-09-03", itemCount: 8 },
    { wishlistId: "WISH004", customerName: "David Wright", createdDate: "2023-09-04", itemCount: 2 },
];

function WishlistsList() {
    const options = {
        filterType: 'checkbox',
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"Wishlists"}
                        data={data}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)', 
                        }}
                    />
                </Box>
            </Grid>
        </Container>
    );
}

export default WishlistsList;
