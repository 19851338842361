import React, { useState } from 'react';
import { Grid, Typography, Container, Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image'; 
import FilterListIcon from '@mui/icons-material/FilterList';
import DeleteIcon from '@mui/icons-material/Delete'; 

function ChangeCenterAd() {
    const [adTitle, setAdTitle] = useState('');
    const [adType, setAdType] = useState('');
    const [adImage, setAdImage] = useState(null); 
    const [openAddAdModal, setOpenAddAdModal] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [ads, setAds] = useState([
        { image: '/images/848.jpg', title: 'Ad 1', description: 'Center' },
        { image: '/images/849.jpg', title: 'Ad 2', description: 'Center' }
    ]); 
    
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setAdImage(URL.createObjectURL(file));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setAds([...ads, { image: adImage, title: adTitle, description: adType }]);
        setAdTitle('');
        setAdType('');
        setAdImage(null);
        handleCloseAddAdModal(); 
    };

    const handleFilterClick = (ad) => {
        setOpenFilterModal(true); 
    };

    const handleDeleteClick = (index) => {
        setAds(ads.filter((_, i) => i !== index));
    };

    const handleOpenAddAdModal = () => setOpenAddAdModal(true);
    const handleCloseAddAdModal = () => setOpenAddAdModal(false);
    const handleCloseFilterModal = () => setOpenFilterModal(false);

    const changeImageButtonStyle = {
        background: '#417690', 
        color: '#fff',
        '&:hover': {
            background: '#5A8EA8', 
        },
    };

    const saveAdButtonStyle = {
        background: '#417690', 
        color: '#fff',
        '&:hover': {
            background: '#5A8EA8', 
        },
    };

    const filterButtonStyle = {
        color: '#417690', 
        '&:hover': {
            color: '#5A8EA8', 
        },
    };

    const deleteButtonStyle = {
        color: '#FF4C4C',                
        '&:hover': {
            color: '#FF6B6B', 
        },
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 0 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%', padding: 2 }}>
                    <Typography variant="h4"
                                gutterBottom
                                sx={{ 
                                    fontSize: '1.25rem', 
                                    textTransform: 'uppercase', 
                                    fontFamily: '"Roboto Condensed", sans-serif',
                                    letterSpacing: 1.2,  
                                    fontWeight: 400,     
                                }}>
                        Center Advertisement
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            sx={{ ...changeImageButtonStyle, marginBottom: 2 }}
                            onClick={handleOpenAddAdModal}
                        >
                            Add Advertisement
                        </Button>
                    </Box>

                    <Box sx={{ marginTop:1, boxShadow: 3, borderRadius: 2 }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell 
                                            sx={{ 
                                                textAlign: 'center', 
                                                backgroundColor: '#f0f0f0', 
                                                fontWeight: 'bold' 
                                            }}
                                        >
                                            Advertisement Image
                                        </TableCell>
                                        <TableCell 
                                            sx={{ 
                                                textAlign: 'center', 
                                                backgroundColor: '#f0f0f0', 
                                                fontWeight: 'bold' 
                                            }}
                                        >
                                            Title
                                        </TableCell>
                                        <TableCell 
                                            sx={{ 
                                                textAlign: 'center', 
                                                backgroundColor: '#f0f0f0', 
                                                fontWeight: 'bold' 
                                            }}
                                        >
                                            Type
                                        </TableCell>
                                        <TableCell 
                                            sx={{ 
                                                textAlign: 'center', 
                                                backgroundColor: '#f0f0f0', 
                                                fontWeight: 'bold' 
                                            }}
                                        >
                                            Filter
                                        </TableCell> 
                                        <TableCell 
                                            sx={{ 
                                                textAlign: 'center', 
                                                backgroundColor: '#f0f0f0', 
                                                fontWeight: 'bold' 
                                            }}
                                        >
                                            Delete
                                        </TableCell> 
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ads.map((ad, index) => (
                                        <TableRow key={index}>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                {ad.image ? (
                                                    <Box sx={{ width: '100%', height: 150, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img
                                                            src={ad.image}
                                                            alt="Ad Image"
                                                            style={{ width: '100%', height: '50%', objectFit: 'contain' }}
                                                        />
                                                    </Box>    
                                                ) : (
                                                    <IconButton
                                                        sx={{ width: 120, height: 80, border: '2px dashed #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                    >
                                                        <ImageIcon sx={{ fontSize: 40, color: '#ccc' }} /> 
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{ad.title}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>{ad.description}</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton
                                                    sx={filterButtonStyle}
                                                    onClick={() => handleFilterClick(ad)}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <IconButton
                                                    sx={deleteButtonStyle}
                                                    onClick={() => handleDeleteClick(index)}
                                                >
                                                    <DeleteIcon /> 
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Grid>

            {/* Add Advertisement Modal */}
            <Dialog open={openAddAdModal} onClose={handleCloseAddAdModal}>
                <DialogTitle>Add Advertisement</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Title"
                            fullWidth
                            variant="standard"
                            value={adTitle}
                            onChange={(e) => setAdTitle(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Type"
                            fullWidth
                            variant="standard"
                            value={adType}
                            onChange={(e) => setAdType(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            component="label"
                            sx={{ ...changeImageButtonStyle, marginTop: 2 }}
                        >
                            Upload Image
                            <input
                                type="file"
                                hidden
                                onChange={handleImageChange}
                            />
                        </Button>
                        {adImage && (
                            <Box sx={{ marginTop: 2 }}>
                                <img
                                    src={adImage}
                                    alt="Preview"
                                    style={{ width: 100, height: 100, objectFit: 'cover' }}
                                />
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddAdModal}>Cancel</Button>
                    <Button onClick={handleSubmit}>Add</Button>
                </DialogActions>
            </Dialog>

            {/* Filter Modal */}
            <Dialog open={openFilterModal} onClose={handleCloseFilterModal}>
                <DialogTitle> </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Category</InputLabel>
                        <Select label="Category">
                            <MenuItem value="Category1">Category1</MenuItem>
                            <MenuItem value="Category2">Category2</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Sub Category</InputLabel>
                        <Select label="Sub Category">
                            <MenuItem value="SubCategory1">SubCategory1</MenuItem>
                            <MenuItem value="SubCategory2">SubCategory2</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Mill</InputLabel>
                        <Select label="Mill">
                            <MenuItem value="Mill1">Mill1</MenuItem>
                            <MenuItem value="Mill2">Mill2</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Size</InputLabel>
                        <Select label="Size">
                            <MenuItem value="Small">Small</MenuItem>
                            <MenuItem value="Medium">Medium</MenuItem>
                            <MenuItem value="Large">Large</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Width</InputLabel>
                        <Select label="Width">
                            <MenuItem value="Narrow">Narrow</MenuItem>
                            <MenuItem value="Regular">Regular</MenuItem>
                            <MenuItem value="Wide">Wide</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Style</InputLabel>
                        <Select label="Style">
                            <MenuItem value="Style1">Style1</MenuItem>
                            <MenuItem value="Style2">Style2</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Type</InputLabel>
                        <Select label="Type">
                            <MenuItem value="Type1">Type1</MenuItem>
                            <MenuItem value="Type2">Type2</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Pattern</InputLabel>
                        <Select label="Pattern">
                            <MenuItem value="Pattern1">Pattern1</MenuItem>
                            <MenuItem value="Pattern2">Pattern2</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Shade</InputLabel>
                        <Select label="Shade">
                            <MenuItem value="Light">Light</MenuItem>
                            <MenuItem value="Medium">Medium</MenuItem>
                            <MenuItem value="Dark">Dark</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Color</InputLabel>
                        <Select label="Color">
                            <MenuItem value="Red">Red</MenuItem>
                            <MenuItem value="Blue">Blue</MenuItem>
                            <MenuItem value="Green">Green</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        label="Min Quantity"
                        type="number"
                        sx={{ marginBottom: 2 }}
                    />

                    <TextField
                        fullWidth
                        label="Max Quantity"
                        type="number"
                        sx={{ marginBottom: 2 }}
                    />

                    <TextField
                        fullWidth
                        label="Tags"
                        variant="standard"
                        sx={{ marginBottom: 2 }}
                    />

                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>Any/All</InputLabel>
                        <Select label="Any/All">
                            <MenuItem value="Any">Any</MenuItem>
                            <MenuItem value="All">All</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFilterModal}>Close</Button>
                    <Button variant="contained" color="primary">Add To Advertisement</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default ChangeCenterAd;
