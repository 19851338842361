import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Grid, Button, Typography, Modal, TextField, IconButton, Link } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import { Box, Container } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';

export default function ProductDetails(){
    const [modalOpen, setModalOpen] = useState(false);
    const [productDetails, setProductList] = useState({
        productName: '',
        description: '',
        price: ''
    });   
    const [productsData, setProductsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchproductsData() {
            let resData = await getData('/api/product/');
            console.log(resData);
            let products_data = resData['data']['products']
            if (products_data) {
                console.log(products_data);
                if (products_data) {
                    setProductsData(products_data);
                }
            } else {
                console.error("Error fetching products data:", resData.message);
            }
            setLoading(false);
        }

        fetchproductsData();
    }, []); 

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProductList((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleFormSubmit = () => {
        console.log('Product Details:', productDetails);
        setModalOpen(false);
    };

    const columns = [
        {
            name: 'ps_product_ID',
            label: 'Product ID',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => (
                    <Link style={{ color: '#0074cd', textDecoration: 'none' }} href={'/admin/products/productDetail?product_id=' + value}>
                    {value}
                    </Link>
                ),
            }
        },
        {
            name: 'ps_product_cat',
            label: 'Product category',
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_productcode',
            label: 'Product code',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_productimg',
            label: 'Product Image',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_purchaserate1',
            label: 'Product purchase rate',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: 'ps_product_sellrate1',
            label: 'Product sell rate',
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
    ];

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
        customToolbar: () => {
            return (
                <IconButton onClick={() => setModalOpen(true)} sx={{ background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' }, mb:0 }}>
                    <AddIcon />
                </IconButton>
            );
        }
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={'Product List'}
                        data={loading ? [] : productsData}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </Grid>

            {/* Modal for adding new product */}
            <Modal open={modalOpen} onClose={handleModalClose} aria-labelledby="add-product" aria-describedby="add-a-new-product">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 6, borderRadius: 2 }}>
                    <Typography id="add-product" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Add New Product
                    </Typography>
                    <TextField label="Product Name" fullWidth variant="outlined" name="productName" value={productDetails.productName} onChange={handleInputChange} sx={{ mb: 3 }} />
                    <TextField label="Description" fullWidth variant="outlined" name="description" value={productDetails.description} onChange={handleInputChange} sx={{ mb: 3 }} />
                    <TextField label="Price" fullWidth variant="outlined" name="price" value={productDetails.price} onChange={handleInputChange} sx={{ mb: 3 }} />
                    <Button variant="contained" sx={{ backgroundColor: '#417690', '&:hover': { backgroundColor: '#5A8EA8' } }} style={{ color: '#fff' }} onClick={handleFormSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
};
