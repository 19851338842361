import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Button, Card, CardContent, Typography, Paper, Modal, TextField } from '@mui/material';
import { getData, postData } from "../../ServerRequest";
import { DataGrid } from '@mui/x-data-grid';
import CardComponent from '../../components/card/CardComponent';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export default function Tags() {
    const [modalOpen, setModalOpen] = useState(false); 
    const [categoryName, setCategoryName] = useState(''); 
    const [rowModalOpen, setRowModalOpen] = useState(false); 
    const [selectedRow, setSelectedRow] = useState(null); 
    const [tagsData, setTagsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchTagsData() {
            let resData = await getData('/api/product/tags');
            console.log(resData);
            let tags_data = resData['data']['tags']
            if (tags_data) {
                console.log(tags_data);
                if (tags_data) {
                    setTagsData(tags_data);
                }
            } else {
                console.error("Error fetching tags data:", resData.message);
            }
            setLoading(false);
        }

        fetchTagsData();
    }, []);

    const columns = [
        {
            name: "ps_tags_ID",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "ps_tags_tags",
            label: "Tag",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "ps_tags_category",
            label: "Tag Category",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            }
        },
        {
            name: "assign_tag",
            label: "Assign To Category",
            options: {
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '20px' }}>
                    <Button style={{ backgroundColor: '#f5f5f5', color: '#417690', '&:hover': { color: '#5A8EA8' }} } sx="center"
                        onClick={() => {
                            setSelectedRow(tableMeta.rowIndex);
                            setRowModalOpen(true);
                        }}>
                        Assign Tag
                    </Button>
                    </div>
                ),
            }
        },
    ];
    
    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleCategoryNameChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleFormSubmit = () => {
        console.log("Category Name:", categoryName);
        setModalOpen(false); 
    };

    const options = {
        filterType: 'checkbox',
        customToolbar: () => {
            return (
                <IconButton sx={{ color: '#417690', backgroundColor: '#EDF4F7' }} onClick={() => setModalOpen(true)}>
                   <AddIcon /> 
                </IconButton>
            );
        },
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)', 
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"Tags"}
                        data={loading ? [] : tagsData}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </Grid>

            {/* Modal for assigning tag to category */}
            <Modal open={rowModalOpen} onClose={() => setRowModalOpen(false)} aria-labelledby="assign-tag-category" aria-describedby="assign-tag-to-category">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 6, borderRadius: 2 }}>
                    <Typography id="assign-tag-category" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Assign Tag To Category
                    </Typography>
                    <TextField label="Category Name" fullWidth variant="outlined" sx={{ mb: 3 }} />
                    <Button variant="contained" sx={{ backgroundColor: '#417690','&:hover': { backgroundColor: '#5A8EA8' } }} style={{ color: '#fff' }} 
                            onClick={() => { console.log("Assign Tag to Category for row:", selectedRow);                            
                            setRowModalOpen(false); 
                        }}>
                        Submit
                    </Button>
                </Box>
            </Modal>

            {/* Modal for creating a new tag category */}
            <Modal open={modalOpen} onClose={handleModalClose} aria-labelledby="create-tag-category" aria-describedby="create-a-new-tag-category">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 6, borderRadius: 2 }}>
                    <Typography id="create-tag-category" variant="h6" component="h2" sx={{ mb: 3 }}>
                        Create Tag Category
                    </Typography>
                    <TextField label="Category Name" fullWidth variant="outlined" value={categoryName} onChange={handleCategoryNameChange} sx={{ mb: 3 }} />
                    <Button variant="contained" sx={{ backgroundColor: '#417690', '&:hover': { backgroundColor: '#5A8EA8' }, }} style={{ color: '#fff' }} onClick={handleFormSubmit}>
                        Submit
                    </Button>
                </Box>
            </Modal>                        
        </Container> 
    );
};


