import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Grid, Box, TableCell, Container, Typography } from '@mui/material';

const columns = [
    {
        name: "queryId",
        label: "Buyer Request ID",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "subject",
        label: "Subject",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value) => {
                let color;
                switch (value) {
                    case "Resolved":
                        color = "green";
                        break;
                    case "Pending":
                        color = "orange";
                        break;
                    case "In Progress":
                        color = "blue";
                        break;
                    default:
                        color = "gray";
                }
                return <span style={{ color }}>{value}</span>;
            }
        }
    },
    {
        name: "date",
        label: "Date",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
];

// Sample data for the table (renamed to 'queries')
const queries = [
    { queryId: "Q123", subject: "Login Issue", status: "Resolved", date: "2024-08-26" },
    { queryId: "Q124", subject: "Payment Error", status: "Pending", date: "2024-08-25" },
    { queryId: "Q125", subject: "Account Verification", status: "In Progress", date: "2024-08-24" },
    { queryId: "Q126", subject: "Technical Support", status: "Resolved", date: "2024-08-23" },
];

// Define options for the MUIDataTable
const options = {
    filterType: 'checkbox',
};

function Queries() {
    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={
                            <Typography variant="h6" component="div">
                                Queries : <span style={{ color: 'orange' }}>{queries.length}</span>
                            </Typography>
                        }
                        data={queries}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
                        }}
                    />
                </Box>
            </Grid>
        </Container>
    );
}

export default Queries;
