import { React, useState } from 'react';
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Grid, Container, Typography, Button, Box, TableCell } from '@mui/material';
import { Add } from '@mui/icons-material'; 

const columns = [
    {
        name: "shipmentId",
        label: "Shipment ID",
        options: {
            filter: true,
            sort: true,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "customerName",
        label: "Customer Name",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "shippedDate",
        label: "Shipped Date",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "deliveryEstimate",
        label: "Delivery Estimate",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
        }
    },
    {
        name: "currentStatus",
        label: "Current Status",
        options: {
            filter: true,
            sort: false,
            customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => (
                <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    {columnMeta.label}
                </TableCell>
            ),
            customBodyRender: (value) => {
                let color;
                switch (value) {
                    case "In Transit":
                        color = "blue";
                        break;
                    case "Out for Delivery":
                        color = "orange";
                        break;
                    case "Delivered":
                        color = "green";
                        break;
                    default:
                        color = "gray";
                }
                return <span style={{ color }}>{value}</span>;
            }
        }
    },
];

const data = [
    { shipmentId: "SHIP001", customerName: "Alice Smith", shippedDate: "2023-09-01", deliveryEstimate: "2023-09-05", currentStatus: "In Transit" },
    { shipmentId: "SHIP002", customerName: "Bob Johnson", shippedDate: "2023-09-02", deliveryEstimate: "2023-09-06", currentStatus: "Out for Delivery" },
    { shipmentId: "SHIP003", customerName: "Charlie Lee", shippedDate: "2023-09-03", deliveryEstimate: "2023-09-07", currentStatus: "Delivered" },
    { shipmentId: "SHIP004", customerName: "David Wright", shippedDate: "2023-09-04", deliveryEstimate: "2023-09-08", currentStatus: "In Transit" },
];

function InTransitList() {
    const options = {
        filterType: 'checkbox',
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                    <MUIDataTable
                        title={"In Transit Shipments"}
                        data={data}
                        columns={columns}
                        options={{
                            ...options,
                            responsive: 'standard',
                            tableBodyHeight: 'calc(100vh - 64px - 48px)', 
                            tableBodyMaxHeight: 'calc(100vh - 64px - 48px)', 
                        }}
                    />
                </Box>
            </Grid>
        </Container>
    );
}

export default InTransitList;
