import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton, TextField, Container, Divider, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getData, postData } from "../../ServerRequest"; 
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

export default function ProductDetail() { 
    const searchParams = new URLSearchParams(window.location.search);
    const productId = searchParams.get('product_id');
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({ 
        ps_product_ID: '',
        ps_product_productcode: '',
        ps_product_cat: '',
        ps_product_productdesc: '',
        ps_product_productimg: '',
        ps_product_prodwho: '',
        ps_product_prodname: '',
        ps_product_desc: '',
        ps_product_sellrate1: '',
        ps_product_sellrate2: '',
        ps_product_sellrate3: '',
        ps_product_subcat: '',
        ps_product_mill: '',
        ps_product_count: '',
        ps_product_composition: '',
        ps_product_size: '',
        ps_product_construction: '',
        ps_product_width: '',
        ps_product_style: '',
        ps_product_quality: '',
        ps_product_type: '',
        ps_product_pattern: '',
        ps_product_shade: '',
        ps_product_color1: '',
        ps_product_color2: '',
        ps_product_color3: '',
        ps_product_color4: '',
        ps_product_color5: '',
        ps_product_purchaserate1: '',
        ps_product_purchaserate2: '',
        ps_product_purchaserate3: '',
        ps_product_createdttime: '',
        ps_product_modifydttime: '',
        ps_product_QQH: '',
        ps_product_SO: '',
        ps_product_IQ: '',
        ps_product_DC: '',
        ps_product_DCvalue: '',
        ps_product_DCqty: '',
        ps_product_rcptqty: '',
        ps_product_issueqty: '',
        ps_product_lastsellrate: '',
        ps_product_COMM: '',
        ps_product_netqty: '',
        ps_product_dim1: '',
        ps_product_dim2: '',
        ps_product_dim3: '',
        ps_product_dim4: '',
        ps_product_dim5: '',
        ps_product_instock: '',
        ps_product_likes: '',
        ps_product_tags: '',
        ps_product_tags2: '',
        ps_product_excelpath: '',
        ps_product_isimgdone: '',
        ps_product_tags3: '',
    });

    const handleProductSubmit = async (event) => {
        console.log(formData);
        setLoading(true);
        event.preventDefault();
        let productReqData = {
            'ps_product_ID': formData.ps_product_ID ? formData.ps_product_ID : '',
            'ps_product_productcode': formData.ps_product_productcode,
            'ps_product_cat': formData.ps_product_cat,
            'ps_product_productdesc': formData.ps_product_productdesc,
            'ps_product_productimg': formData.ps_product_productimg,
            'ps_product_prodwho': formData.ps_product_prodwho,
            'ps_product_prodname': formData.ps_product_prodname,
            'ps_product_desc': formData.ps_product_desc,
            'ps_product_sellrate1': formData.ps_product_sellrate1,
            'ps_product_sellrate2': formData.ps_product_sellrate2,
            'ps_product_sellrate3': formData.ps_product_sellrate3,
            'ps_product_subcat': formData.ps_product_subcat,
            'ps_product_mill': formData.ps_product_mill,
            'ps_product_count': formData.ps_product_count,
            'ps_product_composition': formData.ps_product_composition,
            'ps_product_size': formData.ps_product_size,
            'ps_product_construction': formData.ps_product_construction,
            'ps_product_width': formData.ps_product_width,
            'ps_product_style': formData.ps_product_style,
            'ps_product_quality': formData.ps_product_quality,
            'ps_product_type': formData.ps_product_type,
            'ps_product_pattern': formData.ps_product_pattern,
            'ps_product_shade': formData.ps_product_shade,
            'ps_product_color1': formData.ps_product_color1,
            'ps_product_color2': formData.ps_product_color2,
            'ps_product_color3': formData.ps_product_color3,
            'ps_product_color4': formData.ps_product_color4,
            'ps_product_color5': formData.ps_product_color5,
            'ps_product_purchaserate1': formData.ps_product_purchaserate1,
            'ps_product_purchaserate2': formData.ps_product_purchaserate2,
            'ps_product_purchaserate3': formData.ps_product_purchaserate3,
            'ps_product_createdttime': formData.ps_product_createdttime,
            'ps_product_modifydttime': formData.ps_product_modifydttime,
            'ps_product_QQH': formData.ps_product_QQH,
            'ps_product_SO': formData.ps_product_SO,
            'ps_product_IQ': formData.ps_product_IQ,
            'ps_product_DC': formData.ps_product_DC,
            'ps_product_DCvalue': formData.ps_product_DCvalue,
            'ps_product_DCqty': formData.ps_product_DCqty,
            'ps_product_rcptqty': formData.ps_product_rcptqty,
            'ps_product_issueqty': formData.ps_product_issueqty,
            'ps_product_lastsellrate': formData.ps_product_lastsellrate,
            'ps_product_COMM': formData.ps_product_COMM,
            'ps_product_netqty': formData.ps_product_netqty,
            'ps_product_dim1': formData.ps_product_dim1,
            'ps_product_dim2': formData.ps_product_dim2,
            'ps_product_dim3': formData.ps_product_dim3,
            'ps_product_dim4': formData.ps_product_dim4,
            'ps_product_dim5': formData.ps_product_dim5,
            'ps_product_instock': formData.ps_product_instock,
            'ps_product_likes': formData.ps_product_likes,
            'ps_product_tags': formData.ps_product_tags,
            'ps_product_tags2': formData.ps_product_tags2,
            'ps_product_excelpath': formData.ps_product_excelpath,
            'ps_product_isimgdone': formData.ps_product_isimgdone,
            'ps_product_tags3': formData.ps_product_tags3,
        };
    
        try {
            let productResData = await postData('/api/product/', productReqData);
            if (productResData['data']['code'] === '200') {
                alert('Product saved successfully.');            
                setLoading(false);
                return true;
            } else {
                alert('Error saving product. Please try again.');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error saving product:', error);
        }
    };   

    useEffect(() => {
        async function fetchformData() {
            let resData = await getData('/api/product?product_id=' + productId);
            console.log(resData);
            let product_data = resData['data']['products']
            if (product_data) {
                console.log(product_data);
                if (product_data) {
                    setFormData(product_data);
                    console.log(formData)
                }
            } else {
                console.error("Error fetching product data:", resData.message);
            }
            setLoading(false);
        }
        fetchformData();
    }, []);

    const handleToggleEdit = () => {
        setIsEditing((prev) => !prev);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const img = new Image();
            const imageUrl = URL.createObjectURL(file);
            img.src = imageUrl;
            img.onload = () => {
                if (img.width <= 1440 && img.height <= 300) {
                    setFormData((prevState) => ({
                        ...prevState,
                        ps_product_productimg: file.name, // Store the file object
                        imageUrl: imageUrl  // Store the object URL for preview
                    }));
                } else {
                    alert('Image must be 1440px wide and 300px tall or smaller.');
                }
                URL.revokeObjectURL(imageUrl); // Free up memory
            };
            img.onerror = () => {
                alert('Failed to load image. Please try again.');
                URL.revokeObjectURL(imageUrl); // Free up memory
            };
        }
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: 3, p: 1 }}>
                <Typography variant="h6" sx={{ ml: 2 }}>Product ID : {formData.ps_product_ID}</Typography>
                <IconButton sx={{background: '#f8f9fa', border: '3px solid #b0bec5', color: 'black', fontSize: '0.850rem', '&:hover': { background: '#eceff1' } }} onClick={isEditing ? handleProductSubmit : handleToggleEdit }>
                    {isEditing ? <SaveOutlinedIcon /> : <EditOutlinedIcon />} 
                </IconButton>
            </Box>                        
            <Box sx={{ p: 3, backgroundColor: '#eceff1', boxShadow: 3 }}>                                 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>    
                    <TextField fullWidth label= "Code" value={formData.ps_product_productcode} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Category" value={formData.ps_product_cat} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Product Description" value={formData.ps_product_productdesc} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Tags 3" value={formData.ps_product_tags3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "WHO" value={formData.ps_product_prodwho} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Product Name" value={formData.ps_product_prodname} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Description" value={formData.ps_product_desc} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Sell Rate 1" value={formData.ps_product_sellrate1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Sell Rate 2" value={formData.ps_product_sellrate2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Sell Rate 3" value={formData.ps_product_sellrate3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Sub Category" value={formData.ps_product_subcat} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Mill" value={formData.ps_product_mill} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Count" value={formData.ps_product_count} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Composition" value={formData.ps_product_composition} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Size" value={formData.ps_product_size} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Construction" value={formData.ps_product_construction} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Width" value={formData.ps_product_width} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Style" value={formData.ps_product_style} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Quality" value={formData.ps_product_quality} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Type" value={formData.ps_product_type} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Pattern" value={formData.ps_product_pattern} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Shade" value={formData.ps_product_shade} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Color 1" value={formData.ps_product_color1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Color 2" value={formData.ps_product_color2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Color 3" value={formData.ps_product_color3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Color 4" value={formData.ps_product_color4} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Color 5" value={formData.ps_product_color5} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Purchase Rate 1" value={formData.ps_product_purchaserate1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Purchase Rate 2" value={formData.ps_product_purchaserate2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Purchase Rate 3" value={formData.ps_product_purchaserate3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Created Time" value={formData.ps_product_createdttime} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Modified Time" value={formData.ps_product_modifydttime} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "QQH" value={formData.ps_product_QQH} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "SO" value={formData.ps_product_SO} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "IQ" value={formData.ps_product_IQ} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DC" value={formData.ps_product_DC} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "DC value" value={formData.ps_product_DCvalue} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "DC qty" value={formData.ps_product_DCqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "RCPT qty" value={formData.ps_product_rcptqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Issue qty" value={formData.ps_product_issueqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Last sell rate" value={formData.ps_product_lastsellrate} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "COMM" value={formData.ps_product_COMM} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Net qty" value={formData.ps_product_netqty} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Dim 1" value={formData.ps_product_dim1} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Dim 2" value={formData.ps_product_dim2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Dim 3" value={formData.ps_product_dim3} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Dim 4" value={formData.ps_product_dim4} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Dim 5" value={formData.ps_product_dim5} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "In stock" value={formData.ps_product_instock} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Likes" value={formData.ps_product_likes} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Tags" value={formData.ps_product_tags} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                            
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Tags 2" value={formData.ps_product_tags2} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <TextField fullWidth label= "Excel path" value={formData.ps_product_excelpath} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />
                    <FormControl fullWidth >
                        <InputLabel shrink={true}>Is img done</InputLabel>
                        <Select fullWidth label= "Is img done" value={formData.ps_product_isimgdone} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ mt: 2, backgroundColor: 'white' }} >
                            <MenuItem value={1}>True</MenuItem>
                            <MenuItem value={0}>False</MenuItem>
                        </Select>
                    </FormControl>                            
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 3, flexDirection: 'row', gap: 2 }}>
                    <TextField fullWidth label= "Image" value={formData.ps_product_productimg} onChange={handleInputChange} disabled={!isEditing} variant="outlined" margin="normal" InputLabelProps={{ shrink: true }} sx={{ marginBottom: 0, backgroundColor: 'white' }} />                                                
                    <IconButton component="image" sx={{ color: 'black', mt: 1 }}>
                            <FileUploadOutlinedIcon />
                            <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
                    </IconButton>
                </Box>                                         
            </Box>            
            <Divider sx={{ marginTop: 2 }} />
        </Container>
    );  
}
