import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Grid, Button, Box, TextField, TableCell, Container, IconButton, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductEnquiries = () => {
    const [enquiries, setEnquiries] = useState([]);

    useEffect(() => {
        // Simulate fetching data from the backend
        const fetchEnquiries = async () => {
            // Replace this with your actual API call to fetch enquiries
            const response = [
                { bookingId: 'B123', enquiryComments: 'Need more details', requestSentTo: 'Customer A', postedOn: '2024-09-15 10:30 AM', status: 'Pending' },
                { bookingId: 'B456', enquiryComments: 'Check availability', requestSentTo: 'Customer B', postedOn: '2024-09-14 11:00 AM', status: 'Accepted' },
                { bookingId: 'B789', enquiryComments: 'Price negotiation', requestSentTo: 'Customer C', postedOn: '2024-09-13 09:45 AM', status: 'Rejected' },
            ];
            setEnquiries(response);
        };
        
        fetchEnquiries();
    }, []);

    const handleAction = (action, bookingId) => {
        setEnquiries((prevEnquiries) =>
            prevEnquiries.map((enquiry) =>
                enquiry.bookingId === bookingId ? { ...enquiry, status: action } : enquiry
            )
        );
    };

    const handleDelete = (bookingId) => {
        setEnquiries((prevEnquiries) =>
            prevEnquiries.filter((enquiry) => enquiry.bookingId !== bookingId)
        );
    };

    const columns = [
        {
            name: "bookingId",
            label: "Booking ID",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            },
        },
        {
            name: "enquiryComments",
            label: "Enquiry Comments",
            options: {
                filter: false,
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => (
                    <TextField
                        defaultValue={value}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={2}
                        disabled
                    />
                ),
            },
        },
        {
            name: "requestSentTo",
            label: "Request Sent To",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            },
        },
        {
            name: "postedOn",
            label: "Posted On",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value) => {
                    let color;
                    if (value === 'Pending') color = 'orange';
                    else if (value === 'Accepted') color = 'green';
                    else if (value === 'Completed') color = 'blue';
                    else color = 'red';

                    return <span style={{ color }}>{value}</span>;
                },
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customHeadRender: (columnMeta) => (
                    <TableCell key={columnMeta.index} style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        {columnMeta.label}
                    </TableCell>
                ),
                customBodyRender: (value, tableMeta) => {
                    const bookingId = tableMeta.rowData[0]; // Get the bookingId from row data

                    return (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <IconButton
                                onClick={() => handleAction('Accepted', bookingId)}
                                color="success"
                                title="Accept"
                            >
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => handleAction('Rejected', bookingId)}
                                color="error"
                                title="Reject"
                            >
                                <CancelIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => handleAction('Completed', bookingId)}
                                color="primary"
                                title="Completed"
                            >
                                <DoneIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => handleDelete(bookingId)}
                                color="error"
                                title="Delete"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        tableBodyHeight: 'calc(100vh - 64px - 48px)',
        tableBodyMaxHeight: 'calc(100vh - 64px - 48px)',
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, marginLeft: 10, marginTop: 2 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%' }}>
                <MUIDataTable
                    title={
                        <Typography variant="h6" component="div">
                             Product Enquiries : <span style={{ color: 'orange' }}> {enquiries.length} </span>
                        </Typography>
                    }
                    data={enquiries}
                    columns={columns}
                    options={options}
                />    
                </Box>
            </Grid>
        </Container>
    );
};

export default ProductEnquiries;
