import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Dialog, DialogContent, IconButton, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const productTypes = [
    { name: 'Checks', image: '/images/checks.jpg', products: 120 },
    { name: 'Denim', image: '/images/denim.jpg', products: 80 },
    { name: 'Plain', image: '/images/plain.jpg', products: 150 },
    { name: 'Print', image: '/images/print.jpg', products: 200 },
    { name: 'Stripes', image: '/images/stripes.jpg', products: 90 },
];

function ProductCatalogue() {  
    
    const navigate = useNavigate();

    const handleCardClick = (productType) => {
        navigate('/product-list/:product-type');
    };

    return (
        <Container sx={{ padding: 2, display: 'flex', flexDirection: 'column', backgroundColor: '#EDF4F7', minHeight: '100vh', fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', boxShadow: 4, marginLeft: 10, marginTop: 2 }}>
            <Typography variant="h4" sx={{ fontSize: '1.25rem', textTransform: 'uppercase', fontFamily: '"Roboto Condensed", sans-serif', letterSpacing: 1.2, fontWeight: 400, marginTop: 2, marginBottom: 2, alignSelf: 'flex-start' }}>
                Product Catalogue
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: 4 }}>
                {productTypes.map((product, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ height: 180, margin: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontFamily: '"Roboto Condensed", sans-serif', cursor: 'pointer' }} onClick={() => handleCardClick(product.name)}>
                            <CardMedia 
                                component="img"
                                alt={product.name}
                                height="100"
                                image={product.image}
                                sx={{ height: '100px', width: '100%', objectFit: 'cover' }}
                            />
                            <CardContent sx={{ padding: 1, fontSize: '0.75rem', fontFamily: '"Roboto Condensed", sans-serif' }}>
                                <Typography variant="h6" component="div" sx={{ fontSize: '1rem', fontFamily: '"Roboto Condensed", sans-serif' }}>
                                    {product.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem', fontFamily: '"Roboto Condensed", sans-serif' }}>
                                    Products: {product.products}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default ProductCatalogue;
