import React from 'react';
import { Grid, Card, CardContent, Typography, Container, Box, Divider, TextField, Button } from '@mui/material';

const buttonStyle = {
    background: '#417690',
    color: '#fff',
    fontSize: '0.875rem',
    '&:hover': {
        background: '#5A8EA8',
    }
};

function UpdateProfile() {
    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 0 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%', padding: 2 }}>
                    <Typography variant="h4"
                                gutterBottom
                                sx={{
                                    fontSize: '1.25rem',
                                    textTransform: 'uppercase',
                                    fontFamily: '"Roboto Condensed", sans-serif',
                                    letterSpacing: 1.2,
                                    fontWeight: 400,
                                }}>
                        Update Profile
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    
                    <Card sx={{ boxShadow: 3, borderRadius: 2, backgroundColor: '#EDF4F7' }}>
                        <CardContent>
                            <Typography variant="h6"
                                        gutterBottom
                                        sx={{
                                            fontSize: '0.875rem',
                                            textTransform: 'uppercase',
                                            fontFamily: '"Roboto Condensed", sans-serif',
                                            letterSpacing: 1.1,
                                            fontWeight: 400,
                                        }}>
                                Profile Information
                            </Typography>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }}
                            />
                            <TextField
                                fullWidth
                                label="Company Name"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }}
                            />
                            <TextField
                                fullWidth
                                label="Email/User ID"
                                type="email"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }}
                            />
                            <TextField
                                fullWidth
                                label="Phone Number"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }}
                            />
                            <TextField
                                fullWidth
                                label="Country"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }} 
                            />
                            <TextField
                                fullWidth
                                label="City"
                                variant="outlined"
                                margin="normal"
                                sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }} 
                            />
                            <Button variant="contained" sx={{ ...buttonStyle, marginTop: 2 }}>
                                Update
                            </Button>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Container>
    );
}

export default UpdateProfile;
