import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Container, Box, Divider, TextField, Button } from '@mui/material';

function ChangePassword() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        console.log("Current Password:", currentPassword);
        console.log("New Password:", newPassword);
        console.log("Confirm Password:", confirmPassword);
    };

    const buttonStyle = {
        background: '#417690',
        color: '#fff',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        fontFamily: '"Roboto Condensed", sans-serif',
        letterSpacing: 1.2,
        fontWeight: 400,
        '&:hover': {
            background: '#5A8EA8',
        },
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, fontFamily: '"Roboto Condensed", sans-serif', borderRadius: '8px', marginLeft: 10, marginTop: 0 }}>
            <Grid container sx={{ flex: 1, height: '100%' }}>
                <Box sx={{ width: '100%', height: '100%', padding: 2 }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            fontSize: '1.25rem',
                            textTransform: 'uppercase',
                            fontFamily: '"Roboto Condensed", sans-serif',
                            letterSpacing: 1.2,
                            fontWeight: 400,
                        }}
                    >
                        Change Password
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    
                    <Card sx={{ boxShadow: 3, borderRadius: 2, backgroundColor: '#EDF4F7' }}>
                        <CardContent>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    fontSize: '0.875rem',
                                    textTransform: 'uppercase',
                                    fontFamily: '"Roboto Condensed", sans-serif',
                                    letterSpacing: 1.1,
                                    fontWeight: 400,
                                }}
                            >
                                Update Your Password
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Current Password"
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }} 
                                />
                                <TextField
                                    fullWidth
                                    label="New Password"
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }}
                                />
                                <TextField
                                    fullWidth
                                    label="Confirm New Password"
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    sx={{ fontSize: '0.875rem', mb : 2, '& .MuiInputBase-input': { fontSize: '0.875rem' }, '& .MuiOutlinedInput-root': { backgroundColor: '#fff' } }} 
                                />
                                <Button variant="contained" sx={{ ...buttonStyle, marginTop: 2 }} type="submit">
                                    Update
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </Container>
    );
}

export default ChangePassword;
